@charset "utf-8";
//*****************TOP*****************//
.bold{
  font-weight: bold;
}
.dbl{
  display: inline-block;
}
.sec_top{
  &.pd{
    padding: clamp(50px, 10vw, 100px) 0;
    margin-bottom: 0;
  }
  h2{
    &.type1{
      text-align: center;
      font-weight: bold;
      margin: 0 auto clamp(20px, 3vw, 34px);
     .jp{
      display: block;
      font-size: clamp(26px, 3vw, 36px);
      border-bottom: 2px dotted $color_secondary;
      padding-bottom: .2em;
      margin-bottom: .2em;
      line-height: calc(50 / 36);
     }
     .en{
      color: $color_secondary;
      font-family: Arial, Helvetica, sans-serif;
      font-size: clamp(18px, 2vw, 20px);
     } 
    }

    &.white{
      .jp{
        color: white;
        border-bottom: 2px dotted;
      }
      .en{
        color: white;
      }
    }
  }

  h3{
    margin: 0 auto 1em;
  }
}

.sec_1{
  background: url("/wp-content/uploads/bg_img001.png");
  h2{
    &.type1{
      margin-bottom: clamp(60px, 5vw, 90px);
    }
  }
  .row{
    .col{
      background: white;
      padding: 0 1em 1em;
      border-radius: 20px;
      box-shadow: 0 0 7px 3px rgba($color_text, .1);
      .num{
        text-align: center;
        margin-top: -40px;
      }
      h3{
        font-weight: bold;
        color: $color_primary;
        text-align: center;
        font-size: clamp(20px, 2.4vw, 24px);
        border-bottom: 1px solid;
        margin-top: 1em;
        padding-bottom: .5em;
        margin-bottom: .5em;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .row{
      display: flex;
      flex-wrap: wrap;
      .col{
        &:not(:last-child){
          margin-bottom: 60px;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    
  }
  @media screen and (min-width: 1024px) {
    .row{
      .col{
        padding: 0 calc(25 / 1920 * 100vw) calc(25 / 1920 * 100vw);
        h3{
          letter-spacing: 0;
        }
      }
    }
  }
}

.sec_2{
  background: url("/wp-content/uploads/bg_img002.png") no-repeat center/cover;
}

.sec_3{
  background: url("/wp-content/uploads/bg_img003.png");
  .row{
    .link{
      text-decoration: none;
      background: $color_primary;
      color: white;
      font-weight: bold;
      font-size: clamp(16px, 2vw, 20px);
      border-radius: 10px;
      position: relative;
      height: 70px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 15px;
      transition: all .3s;
      &:after{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 8px;
        height: 8px;
        border-bottom: 1px solid white;
        border-right: 1px solid white;
        transform: rotate(-45deg);
        right: 4%;
      }

      .sec{
        position: relative;
        padding-left: 60px;
        &:before{
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 51px;
          height: 52px;
          left: 0;
        }
      }

      @for $i from 1 through 14 {
        &__#{$i}{
          .sec{
            &:before{
              background: url("/wp-content/uploads/ic#{$i}.png") no-repeat center/contain;
            }
          }
        }
      }

      &:hover{
        background: $color_secondary;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .row{
      gap: calc(20 / 767 * 100vw);
      display: flex;
      flex-wrap: wrap;
      .link{
        width: calc((100% - calc(20 / 767 * 100vw)) / 2);
        padding-left: calc(20 / 767 * 100vw);
        .sec{
          padding-left: 35px;
          &:before{
            width: 30px;
            height: 31px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .row{
      gap: calc(20 / 1920 * 100vw);
      .link{
        width: calc((100% - calc(40 / 1920 * 100vw)) / 3);
        height: 90px;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    
  }
}

.sec_news{
  .news{
    .heading{
      h2{
        margin: 0 auto 1em;
      }
    }
    .content{
      padding: clamp(15px, 2vw, 36px);
      border-radius: 20px;
      dl{
        dt{
          .badge_category{
            @include fontsize(14);
            padding: 2px 5px;
            width: 70px;
          }
        }
        dd{
          a{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            padding-right: 1.5em;
            color: $color_text;
            &:after {
              content: "";
              display: block;
              position: absolute;
              bottom: 49%;
              right: 2%;
              margin: auto;
              width: 5px;
              height: 5px;
              border-bottom: 1px solid $color_primary;
              border-right: 1px solid $color_primary;
              transform: rotate(-45deg);
              transition: 0.2s;
            }

            &:hover{
              color: $color_primary;
              &:after{
                right: 1%;
              }
            }
          }
        }
      }
    }
  }

  .button{
    max-width: 250px;
  }

  @media(min-width: 768px){
    .news {
      > div{
        display: block;
        .heading,.content{
          width: 100%;
        }

        .content{
          dl{
            max-height: 180px;
            dd{
              a{
                padding-right: 2em;
              }
            }
          }
        }
      }
    }

    @media(max-width: 999px){
      .news{
        >div{
          .content{
            dl{
              dt{
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  @media(min-width: 1000px){
    .news{
      > div{
        .content{
          dl{
            @include fontsize(16);
            dt{
              float: left;
              clear: both;
              width: 195px;
              margin: 0;
              padding: 1em 0;
              +dd{
                padding-left: 195px;
              }
            }
            dd{
              padding: 1em 0;
            }
          }
        }
      }
    }
  }
}

.sec_4{
  background: url("/wp-content/uploads/bg_img004.png") no-repeat center/cover;
  .content{
    background: white;
    dl{
      dt{
        .badge_category{
          background: $color_secondary;
        }
      }
      dd{
        a{
          &:after{
            border-color: $color_secondary!important;
          }
          &:hover{
            color: $color_secondary;
          }
        }
      }
    }
  }
}

.sec_5{
  .content{
    background: #f4fced;
  }
}

.sec_6{
  background: url("/wp-content/uploads/bg_img005.png") no-repeat center/cover;
  dl{
    margin-bottom: clamp(20px, 3vw, 35px);
    dt{
      padding: .7em 0;
      width: 80px;
      float: left;
      clear: both;
      +dd{
        padding-left: 80px;
      }
    }
    dd{
      padding: .7em 0;
      margin: 0;
      border-bottom: 1px solid #ddd;
    }
  }
}