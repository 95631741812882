@charset "utf-8";

/* お問い合わせフォーム */
.inquiry {
  .h-adr {
    margin: 0;
    padding: 4%;
    border: $color_highlight_bg solid 5px;
    border-radius: 10px;

    .p-country-name {
      display: none;
    }
  }

  .table-default {
    border: none;

    tr {
      &:not(:last-of-type) {
        border-bottom: 1px solid #cccccc;
      }
    }

    th {
      background: none;
      border: none;
      text-align: left;
      padding: 1.4em 50px 1.2em .5em;
      vertical-align: top;
      font-weight: bold;
      position: relative;
      color: #333;
      @media (max-width: 767px) {
        padding: 1em 0 0;
      }

      .required {
        display: inline-block;
        background: #d43f3a;
        color: white;
        padding: 4px 10px;
        border-radius: 3px;
        @include fontsize(12);
        margin-left: .5em;
        flex-shrink: 0;

        @media (min-width: 768px) {
          position: absolute;
          top: 1.6em;
          right: 0;
        }
      }
    }

    td {
      background: none;
      border: none;
      padding: 1.2em .5em;
      @media (max-width: 767px) {
        padding: .5em 0 1em;
      }
    }
  }

  .input_wrap {
    display: block;
    margin: 0;
    padding: 0 .2em;
    @media (max-width: 767px) {
      margin: 0;
    }

    input, textarea {
      margin: 0;
    }

    &:last-child {
      margin-right: auto;
    }
  }

  .text-2col {
    display: flex;

    .input_wrap {
      flex-basis: 50%;
    }
  }

  input[type="text"],
  input[type="url"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"],
  select,
  textarea {
    width: 100%;
    max-width: 100%;
    padding: 7px 2%;
    color: $color_text;
    @include fontsize(16);
    margin: 0 .2em;
    border: 1px solid #cccccc;
    background: #fafafa;
    border-radius: 3px;
    -webkit-appearance: none;
    @media (max-width: 767px) {
      margin: .2em 0;
    }
  }

  input[type="number"] {
    width: 65px;
    text-align: center;
  }

  input.p-postal-code {
    width: 200px;
  }

  input.p-region {
    width: 100px;
  }

  input.p-locality {
    width: 150px;
  }

  input.p-street-address {
    width: 100%;
  }

  input.p-extended-address {
    width: 100%;
  }

  .mwform-tel-field {
    display: flex;
    justify-content: space-between;
  }

  .submit {
    margin: 1em 0;
    text-align: center;
  }

  .form_button {
    @extend .button;
  }
}


.inquiry {
  .form_button, .wpcf7-submit {
    background: $color_form_button_bg;
    color: $color_form_button_text;
    border-color: $color_form_border;

    &.form_back {
      background: $color_form_back_button_bg;
      border-color: $color_form_back_border;
      color: $color_form_back_button_text;

      &:hover {
        background: $color_form_back_button_bg_hover;
        border-color: $color_form_back_button_border_hover;
        color: $color_form_back_button_text_hover;
      }
    }

    &.form_send {
      background: $color_form_send_button_bg;
      border-color: $color_form_send_border;
      color: $color_form_send_button_text;

      &:hover {
        background: $color_form_send_button_bg_hover;
        border-color: $color_form_send_button_border_hover;
        color: $color_form_send_button_text_hover;
      }
    }
  }
}

@media (min-width: 768px) {
  .inquiry {
    input[type="text"],
    input[type="url"],
    input[type="email"],
    input[type="tel"],
    input[type="date"],
    input[type="text"],
    select {
      //width: 60%;
      margin: 0 .2em;
    }

    input[type="number"] {
      width: 65px;
    }

    input.p-postal-code {
      width: 200px;
    }

    input.p-region {
      width: 100px;
    }

    input.p-locality {
      width: 150px;
    }

    input.p-street-address {
      width: 100%;
    }

    input.p-extended-address {
      width: 100%;
    }

    textarea {
      width: 100%;
    }

    .input_wrap {
      display: block;
      margin: 0 .2em;

      input, textarea {
        margin: 0;
      }

      &:last-child {
        margin-right: auto;
      }
    }

    .submit {
      margin: 60px 0 0;
    }
  }
}

.form_button_wrap {
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: 767px) {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  button {
    margin: .5em !important;
  }
}

.wpcf7-spinner {
  margin: 1em 24px!important;
}

.privacy_wrap {
  margin-top: 5%;
  border: #ccc solid 1px;
  padding: 1em;
  height: 400px;
  overflow-y: scroll;
}

.mw_wp_form_confirm .input-show {
  display: none !important;
}

.mw_wp_form_input .input-hide {
  display: none !important;
}

.mw_wp_form .error {
  color: #d43f3a !important;
}

//CF7確認用
.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  word-wrap: normal !important;
}

.wpcf7 form .wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em;
  border: 2px solid #00a0d2; /* Blue */
}

.wpcf7 form.init .wpcf7-response-output,
.wpcf7 form.resetting .wpcf7-response-output,
.wpcf7 form.submitting .wpcf7-response-output {
  display: none;
}

.wpcf7 form.sent .wpcf7-response-output {
  border-color: #46b450; /* Green */
}

.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output {
  border-color: #dc3232; /* Red */
}

.wpcf7 form.spam .wpcf7-response-output {
  border-color: #f56e28; /* Orange */
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output,
.wpcf7 form.payment-required .wpcf7-response-output {
  border-color: #ffb900; /* Yellow */
}

.wpcf7-form-control-wrap {
  position: relative;
}

.wpcf7-not-valid-tip {
  color: #dc3232; /* Red */
  font-size: 1em;
  font-weight: normal;
  display: block;
}

.use-floating-validation-tip .wpcf7-not-valid-tip {
  position: relative;
  top: -2ex;
  left: 1em;
  z-index: 100;
  border: 1px solid #dc3232;
  background: #fff;
  padding: .2em .8em;
  width: 24em;
}

.wpcf7-list-item {
  display: inline-block;
  margin: 0 0 0 1em;
}

.wpcf7-list-item-label::before,
.wpcf7-list-item-label::after {
  content: " ";
}

.wpcf7-spinner {
  visibility: hidden;
  display: inline-block;
  background-color: #23282d; /* Dark Gray 800 */
  opacity: 0.75;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 100%;
  padding: 0;
  margin: 0 24px;
  position: relative;
}

form.submitting .wpcf7-spinner {
  visibility: visible;
}

.wpcf7-spinner::before {
  content: '';
  position: absolute;
  background-color: #fbfbfc; /* Light Gray 100 */
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border: none;
  border-radius: 100%;
  transform-origin: 8px 8px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion: reduce) {
  .wpcf7-spinner::before {
    animation-name: blink;
    animation-duration: 2000ms;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.wpcf7 [inert] {
  opacity: 0.5;
}

.wpcf7 input[type="file"] {
  cursor: pointer;
}

.wpcf7 input[type="file"]:disabled {
  cursor: default;
}

.wpcf7 .wpcf7-submit:disabled {
  cursor: not-allowed;
}

.wpcf7 input[type="url"],
.wpcf7 input[type="email"],
.wpcf7 input[type="tel"] {
  direction: ltr;
}

.wpcf7-reflection > output {
  display: list-item;
  list-style: none;
}

.wpcf7-reflection > output[hidden] {
  display: none;
}
