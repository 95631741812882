@charset "utf-8";

// ======== key color
$color_primary: #b4cf30; // 基本カラー
$color_secondary: #67c8e7; //サブカラー

// ======== background color
$color_body_bg: #fff; // body背景
$color_highlight_bg: rgba($color_primary, .1); // ハイライトカラー

// ======== text color
$color_text: #333; // 基本文字色
$color_text_reversal: #fff;
$color_text_inverse: $color_text_reversal;
$color_link: $color_primary; // リンクカラー
$color_link_hover: $color_link; // リンクカラー（マウスホバー）

$color_h1_text: $color_text_reversal; // 下層h1テキスト色
$color_h1_bg: $color_primary;
$color_h2_text: $color_text; // h2テキスト色
$color_h2_border: $color_primary; // h2ボーダー色
$color_h3_text: $color_text; // h3テキスト色
$color_h4_text: $color_text; // h4テキスト色

// 帯のテキスト色
$color_highlight_text: $color_text; // 帯の文字色
$color_highlight_link: $color_text; // 帯のリンクカラー
$color_highlight_link_hover: $color_link_hover; // 帯のリンクカラー（マウスホバー）

$color_highlight_h2_text: $color_h2_text; // 帯のh2テキスト色
$color_highlight_h2_border: $color_h2_border; // 帯のh2ボーダー色
$color_highlight_h3_text: $color_h3_text; // 帯のh3テキスト色

// 固定文字色
$color_white: #fff;
$color_black: #000;
$color_red: #f00;

// ======== header

// メインヘッダー(main header)
$color_header_bg: $color_body_bg; // ヘッダー背景色
$color_header_text: $color_text; // ヘッダー文字色

$color_hamburger: $color_primary; // SPメニューボタンの色
$color_navlink_pc: $color_text; // PC Gナビリンクカラー
$color_navlink_pc_hover: $color_primary; // PC Gナビリンクカラー(ホバー)

$color_gnav_bg: rgba($color_primary, .9); // SP Gナビ背景色
$color_navlink_sp: #fff; // SP Gナビリンクカラー
$color_navlink_sp_active: $color_navlink_sp; // SP Gナビリンクカラー（アクティブ）
$color_navlink_sp_active_bg: $color_primary; // SP Gナビリンク背景色（アクティブ）
$color_gnav_border_sp: #fff; // SP Gナビボーダーカラー

// ======== footer
$color_footer_bg: #f4fced; // フッター背景色
$color_footer_text: $color_text; // フッターテキスト色

$color_ftnavlink: $color_footer_text; // フッターリンク色
$color_ftnavlink_hover: $color_ftnavlink; // フッターリンク色（ホバー）
$color_ftnav_border_sp: darken($color_footer_text, 30%); // フッターリンク間のボーダー(SP)

$color_pagetop_bg: url("/wp-content/uploads/top_btn.png") no-repeat center/cover; // トップに戻るボタンの背景色
$color_pagetop_arrow: #fff; // トップに戻るボタンの矢印の色

// ======== table
$color_table_border: #ddd; // テーブルのボーダー色
$color_table_th_bg: $color_primary; // thの背景色
$color_table_th_text: white; // thのテキスト色
$color_table_td_bg: white; // tdの背景色

// メニュー表
$color_menutable_border: $color_primary; // メニュー表のボーダー色

// 診療時間
$color_schedule_border: $color_primary; // ボーダー色
$color_schedule_thead_bg: rgba($color_table_border, .7); // thead > th の背景色
$color_schedule_thead_text: #fff; // thの背景色
$color_schedule_tbody_th_bg: transparent; // thead > th の背景色
$color_schedule_td_bg: transparent; // td の背景色

// effect
$effect_arrow_color: $color_text_reversal;
$effect_more_color: $color_primary;

// ======== button

// デフォルトボタン
$color_button_bg: $color_primary; // 背景色
$color_button_text: white; // テキスト色
$color_button_bg_hover: $color_secondary; // ホバー時背景色
$color_button_text_hover: $color_button_text; // ホバー時テキスト色
$color_button_border_hover: $color_button_bg_hover;

// フォームボタン
$color_form_button_bg: $color_primary; // 背景色
$color_form_button_text: white; // テキスト色
$color_form_border: $color_primary; // ボーダーカラー
$color_form_button_bg_hover: darken($color_primary, 5%); // ホバー時背景色
$color_form_button_text_hover: $color_button_text; // ホバー時テキスト色
$color_form_button_border_hover: $color_button_bg_hover;

$color_form_back_button_bg: #ccc; // 背景色
$color_form_back_button_text: #666; // テキスト色
$color_form_back_border: $color_form_back_button_bg; // ボーダーカラー
$color_form_back_button_bg_hover: darken($color_form_back_button_bg, 5%); // ホバー時背景色
$color_form_back_button_text_hover: $color_form_back_button_text; // ホバー時テキスト色
$color_form_back_button_border_hover: $color_form_back_button_bg_hover;

$color_form_send_button_bg: darken($color_primary, 10%); // 背景色
$color_form_send_button_text: #fff; // テキスト色
$color_form_send_border: $color_form_send_button_bg; // ボーダーカラー
$color_form_send_button_bg_hover: darken($color_form_send_button_bg, 5%); // ホバー時背景色
$color_form_send_button_text_hover: $color_form_send_button_text; // ホバー時テキスト色
$color_form_send_button_border_hover: $color_form_send_button_bg_hover;

// ======== slider
$color_slider_button: #d2d2d2; // ページャーの色
$color_slider_button_active: $color_primary; // ページャーの色（アクティブ）
$color_slider_layer: $color_text; // キャプションの文字色
$color_slider_shadow: #fff; // キャプションのシャドー色

// Type A
$shape_slider_arrow: 'square'; // 前後切り替えボタンの形状 'square' or 'circle'
$color_sliderA_arrow: rgba($color_primary, 1 - 30/100); // 前後切り替えボタンの背景色

// Type B
$color_slider_mask_bg: rgba(#fff, 0.2); // マスクの背景色
$color_sliderB_arrow: #fff; // 前後切り替えボタンの矢印の色

// コンテンツスライダー(contents slider)
$color_contents_slider_arrow: $color_primary; // 前後切り替えボタンの色

// ======== QAパーツ
$color_qa_bg: $color_highlight_bg;




