@charset "utf-8";

table {
  margin: 0 auto;

  td,
  th {
    padding: 0.7em 1em;
    @media (max-width: 767px) {
      padding: 0.7em .3em;
    }
  }

  th {
    text-align: center;
    font-weight: normal;
  }
}

// デフォルトテーブル
.table-default {
  width: 100%;
  table-layout: fixed;

  thead {
    th, td {
      background: $color_secondary;
    }
  }

  td,
  th {
    border: 1px solid $color_table_border;
  }

  th {
    background: $color_table_th_bg;
    color: $color_table_th_text;
  }

  td {
    background: $color_table_td_bg;
  }

  //余白小さめのテーブル
  &.table-mini {
    td,
    th {
      padding: .5em 1em;
      @media (max-width: 767px) {
        padding: .5em .3em;
      }
    }
  }
}

// メニューテーブル(menu table)
.table-menu {
  width: 100%;

  caption {
    margin-bottom: 0.5em;
    text-align: center;
    @include fontsize(20);
  }

  td,
  th {
    padding: 0.7em 1em;
    border-top: 1px solid $color_table_border;
    border-bottom: 1px solid $color_table_border;
    @media (max-width: 767px) {
      padding: 0.7em .3em;
    }
  }

  tbody th {
    text-align: left;
  }

  td:last-child {
    text-align: right;
    white-space: nowrap;
  }
}

.table-schedule {
  width: 100%;

  caption {
    margin-bottom: 0.5em;
    text-align: center;
    @include fontsize(20);
  }

  td,
  th {
    border: 1px solid $color_table_border;
    padding: 0.7em 1em;
    text-align: center;
    @media (max-width: 767px) {
      padding: 0.7em 0.3em;
    }
  }

  thead th {
    background: $color_table_th_bg;
  }

  tbody th {
    background: $color_table_th_bg;
  }

  td {
    background: $color_schedule_td_bg;
  }
}

// レスポンシブテーブル（767以下縦積み表示）
@media (max-width: 767px) {
  .responsive-stack {
    display: block;

    thead {
      display: none;
    }

    tbody,
    td,
    th,
    tr {
      display: block;
    }

    td,
    th {
      width: 100% !important;
    }

    tr:not(:last-child) {
      td,
      th {
        border-bottom: 0;
      }
    }

    tr:last-child {
      :not(:last-child) {
        border-bottom: 0;
      }
    }
  }
}

// レスポンシブテーブル（767以下スクロール表示）
@media (max-width: 767px) {
  .responsive-scroll-container {
    display: block;
    width: 100%;
    min-height: 0.01%;
    overflow-x: scroll;

    .responsive-scroll-inner {
      width: 798px;
      padding: 0 15px 15px;
    }

    table {
      width: 768px;
      margin: 0;
    }
  }
}

table {
  &.table-default {
    &.typeA {
      tr {
        td,
        th {
          border: 0;
        }

        &:nth-child(odd) {
          th {
            background: $color_primary;
          }

          td {
            background: $color_highlight_bg;
          }
        }

        &:nth-child(even) {
          th {
            background: lighten($color_primary, 10%);
          }

          td {
            background: lighten($color_highlight_bg, 10%);
          }
        }
      }
    }

    &.typeB {
      border-spacing: 0;
      overflow: hidden;

      tbody {
        td,
        th {
          background: transparent;
          color: $color_text;
          border: 0;
          border-bottom: 1px solid rgba($color_table_border, 0.1);
        }

        tr {
          position: relative;
        }

        th {
          color: $color_primary;
        }
      }
    }

    &.typeC {
      th {
        background: $color_table_th_bg;
        color: $color_table_th_text;
      }

      td,
      th {
        border: 0;

        &:not(:last-child) {
          border-bottom: 2px $color_body_bg solid;
        }
      }

      tr {
        position: relative;
      }

      td {
        transform: translateX(2px);
      }
    }
  }
}

.highlight {
  table {
    &.table-default {
      &.typeC {
        td,
        th {
          border: 0;

          &:not(:last-child) {
            border-bottom: 2px $color_highlight_bg solid;
          }
        }
      }
    }
  }
}

