@charset "UTF-8";
.button {
  display: inline-block;
  max-width: $button_width;
  width: 100%;
  margin: 0;
  padding: 1.4em 1em;
  border: $style_button_border;
  border-radius: $style_button_border_radius;
  line-height: 1;
  background: $color_button_bg;
  color: $color_button_text;
  text-align: center;
  text-decoration: none;
  appearance: none;
  transition: .3s;
  @include fontsize(16);
  cursor: pointer;

  &:hover {
    background: $color_button_bg_hover;
    color: $color_button_text_hover;
    border-color: $color_button_border_hover;
  }

  /*
* ボタン矢印
*/
  &.arrow {
    position: relative;
    @include fontsize(16);

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      top: 0;
      right: 5%;
      margin: auto;
      width: 7px;
      height: 7px;
      border-bottom: 1px solid white;
      border-right: 1px solid white;
      transform: rotate(-45deg);
      transition: 0.2s;
    }

    &:hover {
      

      &:after {
        right: 4%;
      }
    }
  }

  .ic{
    position: relative;
    display: table;
    text-align: left;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &__mail{
      padding-left: 30px;
      &:before{
        width: 24px;
        height: 17px;
        background: url("/wp-content/uploads/hdr_icon_mail.png") no-repeat center/contain;
      }
    }
  }

  .highlight & {
    color: $color_button_text;

    &:hover {
      color: $color_button_text_hover;
    }
  }

  &.inverse {
    background: $color_primary;
    border-color: white;
    color: white;

    &:after {
      border-color: white;
    }

    &:hover {
      background: $color_secondary;
      color: #fff;
      border-color: $color_secondary;
      &:after {
        border-color: #fff;
      }
    }
  }

  &.block {
    display: block;
    min-width: 100%;
  }

  &.small {
    padding: .6em 1em;
    max-width: unset;
    width: auto;

    &.arrow {
      padding-right: 2.5em;
    }
  }

  + .button {
    margin-left: 0.5em;

    @media (max-width: 767px) {
      margin-left: 0;
      margin-top: 1em;
    }
  }
}
