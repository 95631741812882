@charset "utf-8";
// ループスライダー
.p-loopSlide {
  display: flex;
  width: 100%;
  overflow: hidden;

  ul {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    @media (max-width: 767px) {
      width: 300%;
    }

    &:first-child {
      animation: loop 50s -25s linear infinite;
    }

    &:last-child {
      animation: loop2 50s linear infinite;
    }

    li {
      width: 100%;
      list-style: none;
      padding: 0 0 0 20px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}