@charset "utf-8";
//*****************下層*****************//
.tel_num{
  .js-tel{
    @include fontsize(30);
    position: relative;
    padding-left: 28px;
    font-weight: bold;
    color: $color_text;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 23px;
      height: 22px;
      background: url("/wp-content/uploads/hdr_icon_tel.png") no-repeat center center/cover;
    }
    a{
      color: $color_text;
    }
  }
}


.anchor_list {
  background: $color_highlight_bg;
  padding: 1em;
  list-style: none;
  border-radius: 20px;
  li {
    margin: .5em .5em;
    a {
      padding-left: 20px;
      position: relative;
      color: $color_text;
      text-decoration: none;
      transition: all .3s;
      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 10px;
        border-color: transparent transparent transparent $color_primary;
        position: absolute;
        top: .2em;
        left: 0;
      }

      &:hover{
        color: $color_primary;
      }
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5em 2em;
    gap: 20px;
    &.anchor_center {
      justify-content: center;
    }
    li{
      width: calc((100% - 20px) / 2);
      margin: 0;
    }
    &.col3{
      li{
        width: calc((100% - 40px) / 3);
      }
    }
  }
}

.border-block{
  border: 1px solid $color_secondary;
  padding: 2em 1em;
  @media(max-width: 767px){
    padding: 2em 1em 2em 0;
  }
}

.bg-block{
  background: rgba($color_secondary, .1);
  padding: 4%;
  border-radius: 20px;
}