@charset "utf-8";
//ヘッダA
.globalHeader {
  background: $color_header_bg;
  color: $color_header_text;
  font-size: $header_font_size+px;

  &.high {
    @media (max-width: ($header_break_point - 1)+px) {
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    }
    @if $fixed_header == true {
      @media (min-width: $header_break_point+px) {
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
      }
    }
    @if $header_alpha == true {
      box-shadow: none !important;
    }
  }

  @media (max-width: ($header_break_point - 1)+px) {
    background: $color_header_bg;
    position: fixed;
    z-index: 999;
    width: 100%;
    @if $header_alpha == true {
      background: transparent;
    }
  }

  @if $fixed_header == true {
    @media (min-width: $header_break_point+px) {
      background: $color_header_bg;
      position: fixed;
      z-index: 999;
      width: 100%;
      @if $header_alpha == true {
        background: transparent;
      }
    }
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
    height: $header_height+px;
    @if ($header_container == false) {
      max-width: 100%;
      .globalHeader_top {
        width: 98%;
        margin: 0 auto;
      }
    }
    @media screen and(min-width: $header_break_point+px) {
      height: auto;
      @media screen and (max-width: 1023px) {
        padding: 0 20px;
      }
    }
    @media (max-width: ($header_break_point - 1)+px) {
      height: 60px;
    }
  }

  .globalHeader_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 0;
    @media screen and(min-width: $header_break_point+px) {
      flex-direction: column;
      
    }
  }

  .header_logo {
    width: $logo_width+px;
    height: $logo_height+px;
    margin: 0;
    @media screen and(min-width: $header_break_point+px) {
      width: 100%;
      text-align: left;
      margin: calc(40 / 1920 * 100vw) 0;
    }
    @media (min-width: 768px) and(max-width: ($header_break_point - 1)+px) {
      width: $logo_tab_width+px;
      height: auto;
    }
    @media (max-width: 767px) {
      width: $logo_sp_width+px;
      height: auto;
      margin-left: calc(20 / 767 * 100vw);
    }

    a {
      display: block;
      height: auto;
      line-height: 0;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@if $header_alpha == true {
  body.home {
    .globalHeader {
      width: 100%;
      z-index: 999;
      background: transparent;
      color: $color_text_reversal;
      @media (min-width: $header_break_point+px) {
        position: absolute;
      }

      .global_nav > ul li a {
        color: $color_text_reversal;

        &:before {
          background: $color_text_reversal;
        }
      }

      .menu-item-has-children {
        a {
          &:after {
            border-color: $color_text_reversal;
          }
        }
      }
    }
  }
}

.global_nav {
  width: auto;
  @if $navi_event_type == 'click' {
    list-style-position: inherit;
  }
  @if $navi_event_type == 'hover' {
    list-style-position: inside;
  }
  @media (max-width: ($header_break_point - 1)+px) {
    position: absolute;
    top: #{$sp_header_height}px;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$sp_header_height}px);
    height: calc(100dvh - #{$sp_header_height}px);//safari用fix
    z-index: 56;
    transition: all .3s;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @media (min-width: $header_break_point+px) {
    width: 100%;
  }


  > ul {
    list-style: none;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    @media (min-width: $header_break_point+px) {
      margin-bottom: 20px;
    }
    @media (max-width: ($header_break_point - 1)+px) {
      display: block;
      //min-height: 100vh;
      background: $color_gnav_bg;
      width: 80vw;
      max-width: 400px;
      transform: translateX(100%);
      transition: all .3s;
      height: 100%;
      overflow: auto;
    }

    li {
      @media (max-width: ($header_break_point - 1)+px) {
        border-bottom: $color_gnav_border_sp solid 1px;
        &:first-child {
          border-top: $color_gnav_border_sp solid 1px;
        }
      }

      @media (min-width: $header_break_point+px) {
        flex: 1 1 auto;
      }

      a {
        color: $color_navlink_pc;
        text-decoration: none;
        padding: .5em 1em;
        transition: all .3s;

        @media (min-width: $header_break_point+px) {
          position: relative;
          width: 100%;
          text-align: center;
          display: block;
          padding: .3em .5em;
          &:before {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background: black;
          }
        }

        @media (max-width: ($header_break_point - 1)+px) {
          display: block;
          padding: 1em;
          color: $color_navlink_sp;
          position: relative;

          &:after {
            content: '';
            width: 8px;
            height: 8px;
            border: 0;
            border-top: solid 2px #fff;
            border-right: solid 2px #fff;
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            right: 1em;
            margin-top: -4px;
          }
        }

        &:hover {
          color: $color_primary;
        }
      }

      &:last-child{
        a{
          @media (min-width: $header_break_point+px) {
            position: relative;
            &:after {
              content: '';
              width: 1px;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              background: black;
            }
          }
        }
      }
    }
  }

  &.open {
    @media (max-width: ($header_break_point - 1)+px) {
      opacity: 1;
      visibility: visible;
      left: 0;
      background: rgba(black, 0.8);
    }

    > ul {
      @media (max-width: ($header_break_point - 1)+px) {
        transform: translateX(0);
      }
    }
  }
}

/**ハンバーガーメニュー**/
#headerToggle {
  display: none;
}

@media (max-width: ($header_break_point - 1)+px) {
  #headerToggle {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    z-index: 99;
    cursor: pointer;
    width: 30px;
    height: 25px;
    transition: all .3s;

    @if $hamburger_menu_type == 'B'{
      height: 19px;
      transform: translateY(-4px);
    }

    span {
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $color_hamburger;
      transition: all .4s;

      &:nth-of-type(1) {
        top: 0;
      }

      &:nth-of-type(2) {
        top: 11px;
        @if $hamburger_menu_type == 'B' {
          top: 8px;
        }
      }

      &:nth-of-type(3) {
        bottom: 0;
      }
    }

    @if $hamburger_menu_type == 'B' {
      &:after{
        content: 'MENU';
        position: absolute;
        top: auto;
        bottom: -16px;
        left: -100%;
        right: -100%;
        margin: auto;
        width: 100%;
        font-size: 8px!important;
        letter-spacing: -0.02em;
        color: $color_hamburger;
        text-align: center;
        font-weight: bold;
      }
    }



    &.active {

      span {
        @if $hamburger_menu_type == 'B' {
          width: 80%;
          left: 10%;
        }

        &:nth-of-type(1) {
          transform: translateY(11px) rotate(-45deg);
          @if $hamburger_menu_type == 'B' {
            transform: translateY(8px) rotate(-45deg);
          }
        }

        &:nth-of-type(2) {
          left: 50%;
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: translateY(-11px) rotate(45deg);
          @if $hamburger_menu_type == 'B' {
            transform: translateY(-8px) rotate(45deg);
          }
        }
      }

      @if $hamburger_menu_type == 'B' {
        &:after {
          content: 'CLOSE';
          letter-spacing: -0.1em;
        }
      }
    }
  }
}

//ヘッダ透過時調整用
body.home {
  @if $header_alpha == true {
    @media (max-width: ($header_break_point - 1)+px) {
      #headerToggle {
        span {
          background-color: $color_text_reversal;
        }
      }
    }
  }
}

//ヘッダ電話番号等
.hdr_info_wrapper {
  display: none;
  @media (min-width: $header_break_point+px) {
    display: block;
    position: absolute;
    top: calc(18 / 1920 * 100vw);
    right: 0;
  }

  .hdr_info {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .hdr_info_group {
    @include listclear();
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      &:not(:last-child){
        margin-right: 10px;
      }
    }

    .button {
      min-width: 250px;
      border-radius: 40px;
      padding: calc(16 / 1920 * 100vw) 16px;
    }
  }
}

.sp_hdr_info_wrapper {
  display: none;
  @media (max-width: ($header_break_point - 1)+px) {
    display: block;
    position: absolute;
    top: 15px;
    right: 70px;
  }

  .sp_hdr_info_group {
    @include listclear();
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      margin-left: 10px;

      &.hdr_icon_tel {
        color: white;

        a {
          color: $color_primary;
        }

        i {
          @include fontsize(32);
        }
      }

      &.hdr_icon_mail {
        color: white;

        a {
          color: $color_primary;
        }

        i {
          @include fontsize(32);
        }
      }
    }
  }
}

//ヘッダ透過時調整用
body.home {
  @if $header_alpha == true {
    .hdr_icon_tel, .hdr_icon_mail {
      a {
        color: $color_text_reversal !important;
      }
    }
  }
}
