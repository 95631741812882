@charset "utf-8";
footer {
  position: relative;
  padding: 60px 0;
  font-size: $footer_font_size+px;
  @media (max-width: 767px) {
    padding: 0 0 1em;
  }

  .fNav {
    ul {
      @include listclear();

      li {
        + li {
          border-top: 1px solid rgba($color_primary, 0.3);
        }

        a {
          display: block;
          padding: 1em 15px;
          text-decoration: none;
          &:hover{
            text-decoration: underline;
          }
        }

        &.menu-item-has-children {
          ul {
            display: block !important;
          }
        }
      }
    }
  }

  .copy_right {
    @include fontsize(10);
  }
}

@media (max-width: 767px) {
  footer {
    ul {
      border-top: 1px solid rgba($color_primary, 0.3);

      li {
        > ul {
          border-top: 1px solid rgba($color_primary, 0.3);
        }

        &.menu-item-has-children {
          ul {
            display: block !important;
            height: auto !important;

            a {
              font-size: 90%;
              padding-left: 1.5em;
            }

            ul {
              a {
                padding-left: 2.5em;
              }
            }
          }
        }
      }
    }

    .col {
      margin-bottom: 0 !important;
    }
  }
}

@media (min-width: 768px) {
  footer {
    .fNav {
      margin-bottom: 5%;

      ul {
        width: 100%;
        text-align: left;
        border-bottom: none;
        
        li {
          border: none !important;

          a {
            padding: .2em 1em .2em 1em !important;
            text-decoration: none;
            position: relative;

            &:before {
              content: '';
              width: 6px;
              height: 6px;
              border: 0;
              border-top: solid 2px $color_primary;
              border-right: solid 2px $color_primary;
              transform: rotate(45deg);
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -5px;
            }
          }

          &.menu-item-has-children{
            ul{
              display: flex!important;
              flex-wrap: wrap;
              column-gap: 2%;
              li{
                width: 49%;
              }
            }
          }

          > ul {
            padding-left: 1em;
            margin-top: .6em;
            a {
              font-size: 90%;

              &:before {
                width: 5px;
                height: 1px;
                border: none;
                background: $color_text;
                margin-top: -2px;
                transform: none;
              }
            }

            .sub-menu {
              padding-left: 1em;

              a {
                &:before {
                  width: 5px;
                  height: 2px;
                  border: none;
                  background: $color_primary;
                  border-radius: 0;
                  transform: rotate(0);
                }
              }
            }
          }
        }
      }
    }
  }
}

.copy_right {
  background: #e0f0d2;
  color: $color_text;
  @include fontsize(16);
  margin: 0;
  padding: 2em 0;
  @media (max-width: 767px) {
    padding: 1em 0;
  }
}

// スマホナビゲーション折り返し設定（タブレット）
@media (min-width: 768px) and (max-width: 1023px) {
  footer nav ul li {
    &.tablet-break:after {
      content: "\A";
      white-space: pre;
    }

    &.tablet-break + li:before {
      display: none;
    }
  }
}

footer {
  background: $color_footer_bg;
  color: $color_footer_text;

  a {
    color: $color_ftnavlink;

    &:hover {
      color: $color_ftnavlink_hover;
    }
  }

  @media (min-width: 768px) {
    .container {
      .row {
        nav {
          ul {
            display: inline-block;
          }

          a {
            display: inline-block;
            padding: 0.3em;
          }
        }
      }
    }
  }
}