@charset "utf-8";
// STEPパーツ(STEP parts)
.p-step {
  .row{
    &:not(:last-child){
      position: relative;
      margin-bottom: 40px;
      &:after {
        content: '';
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
        margin: auto;
        background: $color_primary;
        height: 20px;
        width: 60px;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
      }
    }
  }
}
